import Menu03 from '@/components/icons/menu-03';
import Logo from '@/public/logos/logo.svg';
import Image from 'next/image';
import Link from 'next/link';
import { memo } from 'react';

function NavMobile({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (isOpen: boolean) => void }) {
  return (
    <nav className="sticky top-0 right-0 left-0 z-50 min-h-[5rem] bg-white px-5 shadow-[0_0_20px_0_rgba(115,108,165,0.10)] transition-all lg:hidden">
      <ul className="flex min-h-[5rem] items-center justify-between">
        <li>
          <Link href="/" aria-label="Lien pour retourner à la page d'accueil">
            <Image src={Logo} alt="" role="presentation" width={123} priority />
          </Link>
        </li>

        <li>
          <button
            className="flex h-10 w-10 items-center justify-center rounded-[14px] border-[0.0625rem] border-grey-light"
            type="button"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            aria-expanded={isOpen}
            aria-label={isOpen ? 'Fermer le menu de navigation' : 'Ouvrir le menu de navigation'}
          >
            <Menu03 color="#19162F" width={24} height={24} />
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default memo(NavMobile);
