import { MenuItemWithEmoji } from '@/models/menu-item';

export const menuItems: MenuItemWithEmoji[] = [
  {
    id: 'menu-features',
    text: 'Fonctionnalités',
    emoji: 'partyPopper',
    link: '/features',
  },
  {
    id: 'menu-price',
    text: 'Tarifs',
    emoji: 'coin',
    link: '/price',
  },
  {
    id: 'menu-blog',
    text: 'Blog',
    emoji: 'newspaper',
    link: '/blog',
  },
];
