'use client';

import FooterItem from '@/components/home/footer/footer-item';
import { HomeFooterItems } from '@/contents/home-footer';
import { memo } from 'react';

function Footer() {
  return (
    <footer className="bg-black-text px-5 pt-10 pb-[1.75rem] lg:px-20 lg:pt-[3.75rem] lg:pb-[9.6875rem]">
      <ul className="grid auto-rows-min gap-10 lg:grid-cols-4 lg:grid-rows-1 lg:gap-5">
        {HomeFooterItems.map((item) => (
          <FooterItem item={item} key={item.id} />
        ))}
      </ul>
    </footer>
  );
}

export default memo(Footer);
